import React from 'react'
import "../styles/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderBar from '../components/headerBar';
import AboutSection from '../components/aboutSection';
export default function about() {
    return (
        <div className=''>
            <HeaderBar />
            <AboutSection/>
        </div>
    )
}

import React from 'react'
import SchoolLogo from "../images/OntarioTechUniversityLogo.svg"
export default function aboutSection() {
    return (
        <div className='aboutPage'>
            <div className='shadow-lg mt-2 p-3 bg-white rounded educationParagraph'>
                <h2>Education</h2>
                <div className='Education'>
                    <div className='d-flex flex-row'>
                        <img src={SchoolLogo} alt="OTech" />
                        <div className='eduHeading'>
                            <h3 className='font-weight-bold'>Ontario Tech University</h3>
                            <h5 className='font-weight-bold'>Bachelor of Engineering - B.E., Software Engineering</h5>
                            <h6 className='font-weight-bold'>September 2018 - May 2023</h6>
                        </div>
                        
                    </div>
                    <hr className='w-100'/>
                    <p>
                       <span className='font-weight-bold'> Programming related course work:</span> Object Oriented Programming, Data Structures, 
                       Design and Analysis of Algorithms, Software Project Management
                    </p>
                </div>
            </div>
        </div>
    )
}
